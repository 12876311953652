import React from "react";
import Search from "./Widget/SearchBar/Search";
import { Routes, Route } from "react-router-dom";
import Chatbot from "./Widget/MinksyChatbot/chatbot";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<></>} />
      <Route path="/search" element={<Search />} />
      <Route path="/chatbot" element={<Chatbot />} />
    </Routes>
  );
};

export default App;
