import { createChatBotMessage } from 'react-chatbot-kit';
import DogPicture from './DogPicture';

const config = {
  botName: "Minksy",
  headerTitle: "Minksy", 
  initialMessages: [createChatBotMessage("Hello, I am Minsky, How may I help you today?",)],
  widgets: [
    {
      widgetName: 'dogPicture',
      widgetFunc: (props) => <DogPicture {...props} />,
    },
  ],


};

export default config;
