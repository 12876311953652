import React from 'react';
import axios from 'axios'; 
import MINSKY_URL from '../../config';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const handleHello = () => {
    const botMessage = createChatBotMessage('Hello. Nice to meet you.');

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleDog = () => {
    const botMessage = createChatBotMessage(
      "Here's a nice dog picture for you!",
      {
        widget: 'dogPicture',
      }
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleDefault = async (query) => {

    

    try {
      
      const response = await axios.post(`${MINSKY_URL.getUserQueryResponseChatbot}`, { query });
      const data = response.data;

     const respQuery = data?.answer?.answer;
     
      if (data && respQuery) {
        const botMessage = createChatBotMessage(respQuery);

        setState((prev) => ({
          ...prev,
          messages: [...prev.messages, botMessage],
        }));
      } else {
        const botMessage = createChatBotMessage("We are working on it. Please try after some time.");

        setState((prev) => ({
          ...prev,
          messages: [...prev.messages, botMessage],
        }));
      }
    } catch (error) {
      console.error('Error fetching data:', error);

      const botMessage = createChatBotMessage("We are working on it. Please try after some time.");

      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage],
      }));
    }
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleHello,
            handleDog,
            handleDefault,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
