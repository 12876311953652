
const ENV_PROD = true; // For proudction make it true, don't change in the local environment
let minsky_url = "";

if (ENV_PROD) {
  minsky_url = "https://uatdj.neurobridge.tech"

} else {
  minsky_url = "http://216.48.183.177:8083";

}


const API_LIVE = {
  BASE_URL_MINSKY: minsky_url,
  
  //----------------------------------------------------------
  getUserQueryResponse:`${minsky_url}/minsky/demo/genai-search/`,
  getUserQueryResponseChatbot:`${minsky_url}/minsky/demo/genai-search/`,


};

const MINSKY_URL = API_LIVE;

export default MINSKY_URL;


